import * as React from 'react';
import { withLayout } from '../components/Layout';
import { FixedMasthead } from '../components/Masthead/FixedMasthead.component';
import { Constrain, Prose } from '../components/ui';
import Helmet from 'react-helmet';
import { BannerAdvert } from '../components/BannerAdvert/BannerAdvert.component';
import styled from '../styled-components';

const MinHeightConstrain = styled(Constrain)`
    min-height: 80rem;
`;

const PrivacyPage = () => (
    <>
        <Helmet>
            <title>Cookies</title>

            <meta
                name="description"
                content="What cookies we store and what they are used for."
            />
        </Helmet>

        <FixedMasthead />

        <BannerAdvert />

        <MinHeightConstrain type="narrow">
            <Prose>
                <h1>Cookies</h1>

                <p>
                    We've taken steps to ensure your data is kept private when
                    using our website by only requiring the use of necessary
                    cookies. This has included removing tools like Google
                    Analytics and Twitter Widgets so that we don't have to throw
                    up a horrible cookie banner as soon as you hit our website.
                </p>

                <table>
                    <thead>
                        <tr>
                            <th>Cookie name</th>
                            <th>What it's used for</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className="break-word">
                                gatsby-plugin-offline-precache-https___wemakewaves_digital_#precached-det
                                ails-models
                            </td>
                            <td>
                                We use this cookie to enable offline use of our
                                website. This means we can reduce load times and
                                create a seamless browser experience even when
                                you don't have a stable internet connection.
                            </td>
                        </tr>

                        <tr>
                            <td className="break-word">
                                keyval-store#precached-details-models
                            </td>
                            <td>
                                We use this cookie to enable offline use of our
                                website. This means we can reduce load times and
                                create a seamless browser experience even when
                                you don't have a stable internet connection.
                            </td>
                        </tr>
                        <tr>
                            <td className="break-word">@@scroll#</td>
                            <td>
                                Typically found in session storage (although it
                                may fallback into a cookie), this improves the
                                overall user experience of the website by
                                remembering scroll positions when you navigate
                                between pages.
                            </td>
                        </tr>
                    </tbody>
                </table>
            </Prose>
        </MinHeightConstrain>
    </>
);

export default withLayout(PrivacyPage);
